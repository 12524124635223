import { css } from '@emotion/react'

import { lock } from '../utils/css'
import {
  BRAND_BLACK,
  BRAND_GREEN,
  MQ,
  desktop,
  centerVertically,
} from '../design.styles'

// -- Signup
const SIGNUP_HEIGHT_PHONE_SMALL = '2.875rem'
const SIGNUP_HEIGHT_DESKTOP = '3.6875rem'

const SIGNUP_WIDTH_PHONE_SMALL = '16.875rem'
const SIGNUP_WIDTH_DESKTOP = '26.875rem'

const SIGNUP_BUTTON_WIDTH_PHONE_SMALL = '7rem'
const SIGNUP_BUTTON_WIDTH_DESKTOP = '11.6875rem'

const borderRadius = (side) => css`
  border-top-${side}-radius: calc(${SIGNUP_HEIGHT_PHONE_SMALL} / 2);
  border-bottom-${side}-radius: calc(${SIGNUP_HEIGHT_PHONE_SMALL} / 2);

  ${desktop()} {
    border-top-${side}-radius: calc(${SIGNUP_HEIGHT_DESKTOP} / 2);
    border-bottom-${side}-radius: calc(${SIGNUP_HEIGHT_DESKTOP} / 2);
  }
`

const signup = css`
  .signup .confirmation {
    font-size: 1rem;
    margin: 0.25rem 0;
    height: 1rem;
  }

  .signup .form-container {
    display: flex;
    justify-content: center;

    margin-top: 1.875rem;

    height: ${SIGNUP_HEIGHT_PHONE_SMALL};

    ${MQ[2]} {
      justify-content: center;
      align-items: center;
      margin: 0 2.5rem 0 0;

      height: ${SIGNUP_HEIGHT_DESKTOP};

      max-width: ${SIGNUP_WIDTH_DESKTOP};
    }

    input[type='email'],
    button {
      height: 100%;

      border: 1px solid #101820;
    }

    input[type='email'] {
      box-sizing: border-box;

      ${borderRadius('left')}

      padding-left: 0.9375rem;

      max-width: calc(
        ${SIGNUP_WIDTH_PHONE_SMALL} - ${SIGNUP_BUTTON_WIDTH_PHONE_SMALL}
      );

      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
      }

      ${lock(16, 21, 'font-size')}
      ${lock(20, 26, 'line-height')}
      
      ${desktop()} {
        max-width: calc(
          ${SIGNUP_WIDTH_DESKTOP} - ${SIGNUP_BUTTON_WIDTH_DESKTOP}
        );
      }
    }

    button {
      ${borderRadius('right')}

      background-color: ${BRAND_GREEN};

      min-width: 7rem;

      font-weight: 500;

      ${lock(16, 22, 'font-size')}
      ${lock(20, 27, 'line-height')}
      
      ${desktop()} {
        min-width: 12.6875rem; /* 203.93px */
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .signup {
    margin-left: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const content = css`
  .content {
    display: flex;

    flex-direction: column;

    margin: 0 auto;
    margin-top: 2.0625rem;
    max-width: 25rem;

    font-family: 'Avenir Next Regular', Arial, Helvetica, sans-serif;
    font-weight: 500;

    ${lock(25.5, 42, 'font-size')}
    ${lock(30, 47, 'line-height')}

    letter-spacing: -0.02em;

    z-index: 2;

    .message {
      margin: 0 1.5rem;
    }

    ${MQ[2]} {
      flex-direction: row;
      max-width: 16.875rem;
      ${centerVertically}

      /* ...And remove the mobile margin-top */
      margin-top: 0;
      margin-left: 6.5625rem;

      max-width: unset;

      .message {
        max-width: 32.8125rem;
        margin: 0;
      }
    }

    ${signup}
  }
`

/* Note: In cases where the design specifies fractional pixels, I've rounded down and have included the original values in a comment */
const emailSignup = css`
  display: flex;

  flex-direction: column;

  height: 15.3125rem;

  color: #fff;
  background-color: ${BRAND_BLACK};
  .background {
    max-height: 7.0625rem;
    ${centerVertically}
    @media (max-width: 33.625rem) {
      display: none;
    }
  }

  ${content}

  ${desktop()} {
    position: relative;

    height: 16.4375rem;

    flex-direction: row;
  }
`

export default { emailSignup }
