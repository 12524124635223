import React, { useState } from 'react'

import addToMailchimp from 'gatsby-plugin-mailchimp'

import styles from './EmailSignup.styles.js'

export default () => {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const handleSignUp = async (e) => {
    e.preventDefault()
    const { result, msg } = await addToMailchimp(email)
    setEmail('')
    if (result === 'error') {
      if (msg.includes('already subscribed')) {
        setMessage(`${email} is already subscribed`)
      } else {
        setMessage(msg)
      }
      setTimeout(() => {
        setMessage('')
      }, 3000)
    } else {
      setMessage('Thank you for subscribing to our newsletter! 🐶😸🎉')
      setTimeout(() => {
        setMessage('')
      }, 3000)
    }
  }

  return (
    <>
      <div css={styles.emailSignup}>
        <img
          className="background"
          src="/img/dots.png"
          alt="Sign up for our newsletter"
        />
        <div className="content">
          <div className="message">
            Become a Kinsider and get next level pet care news in your inbox.
          </div>
          <div className="signup">
            <div className="form-container">
              <input
                type="email"
                placeholder="Email"
                aria-label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button onClick={(e) => handleSignUp(e)}>Sign Me Up</button>
            </div>
            <p className="confirmation">{message}</p>
          </div>
        </div>
      </div>
    </>
  )
}
