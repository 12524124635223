import { css } from "@emotion/react";

const style = css`
  .hero {
    h2 {
      display: none;
    }
  }
`;

export default style;
