import styled from '@emotion/styled'
import { BRAND_BLUE, MQ } from '../design.styles'
import { lock } from '../utils/css'

export const PledgeSection = styled.section`
  display: flex;
  flex-direction: column;

  ${MQ[1]} {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 2rem;
  }

  .text-pledge {
    margin-top: 0.5rem;

    ${MQ[1]} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 1.25rem;
    }
  }

  button {
    font-weight: normal;
    font-family: 'Avenir Next Demi', Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    color: ${BRAND_BLUE};
    background: transparent;
    padding-left: 0;
    border: none;

    &:hover {
      cursor: pointer;
    }

    &::after {
      display: inline-block;
      content: ' ';
      height: 0.625rem;
      width: 0.9375rem;
      mask: url('/img/arrow-right-content.svg') no-repeat 50% 50%;
      -webkit-mask: url('/img/arrow-right-content.svg') no-repeat 50% 50%;
      -webkit-mask-size: cover;
      mask-size: contain;
      background-color: currentColor;
    }
  }

  .rotate::after {
    margin-left: 0.25rem;
    height: 0.75rem;
    transform: rotate(-90deg);
  }

  .three-dots {
    color: red;
    font-size: 4rem;
    line-height: 0;
    margin: 0 0 2.4rem;
  }
`

export const StepsSection = styled.section`
  ol {
    margin: 0;
    padding-top: 1.7rem;
    list-style: none;
    position: relative;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;

    ${MQ[1]} {
      padding-top: 3rem;
      padding-bottom: 1.5rem;
    }

    li {
      counter-increment: custom;
      display: flex;

      ${MQ[1]} {
        width: 30%;
        margin-right: 1.8rem;
      }

      ${MQ[3]} {
        width: 30.3%;
      }
    }

    li:before {
      content: counter(custom) ' ';
      font-family: 'Avenir Next Regular', Arial, sans-serif;
      font-weight: 600;
      color: ${BRAND_BLUE};
      margin-right: 0.75rem;
      padding-top: 1rem;
    }

    li:first-of-type {
      counter-reset: custom;
    }
  }
`

export const LookingForwardSection = styled.section`
  font-family: 'Avenir Next Regular', Arial, sans-serif;

  .looking-forward-intro > p {
    ${lock(22, 28, 'font-size')}
    ${lock(22, 32, 'line-height')}
  }

  .text-looking-forward > p {
    ${lock(16, 20, 'font-size')}
    ${lock(20, 24, 'line-height')}
  }

  .content-looking-forward {
    ${MQ[1]} {
      padding-left: 0.5rem;
    }
  }
`
