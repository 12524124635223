import React from 'react'

import PropTypes from 'prop-types'

import { BRAND_GREEN } from '../design.styles'
import style from './ImgAboveTop.styles'

const ImgAboveTop = ({
  background,
  title,
  text,
  linkUrl,
  linkText,
  imageUrl,
  imageText,
}) => (
  <div css={style} style={{ backgroundColor: background }}>
    <div className="content">
      <div className="title">{title}</div>
      <div className="text">{text}</div>
      <div className="link">
        <a
          href={linkUrl}
          target="_blank"
          rel="noreferrer"
          title="Opens in new window"
        >
          {linkText}
        </a>
      </div>
    </div>
    <div className="image">
      <img src={imageUrl} alt={imageText} />
    </div>
  </div>
)

ImgAboveTop.defaultProps = {
  background: BRAND_GREEN,
  title: 'Explore more careers in pet care.',
  text:
    'Check out these open other roles from our partners and friends in pet care.',
  linkUrl: 'https://jobs.kinship.co/',
  linkText: 'View Industry Careers',
  imageUrl: '/img/career-cat.png',
  imageText: 'Grey kitten',
}

ImgAboveTop.propTypes = {
  background: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  imageText: PropTypes.string.isRequired,
}

export default ImgAboveTop
