import { css } from '@emotion/react'
import { BRAND_PRIMARY } from '../design.styles'

const style = css`
  h2 {
    font-family: 'Avenir Next Regular', Arial, sans-serif;
    font-size: 1.5rem;
    line-height: 1.75rem;
    margin: 0 0 3.125rem;
    text-align: center;
  }

  h3 {
    font-family: 'Avenir Next Regular', Arial, sans-serif;
    line-height: 1.4375rem;
    margin: 0 0 1.5rem;
    a {
      text-decoration: none;
    }
  }

  .divider {
    border-top: 1px solid rgba(16, 24, 32, 0.2);
    margin: 0 auto 3.125rem;
    width: 90%;
  }

  .label {
    font-size: 0.75rem;
    margin: 2.625rem 0 1.0625rem;
    text-align: center;
    text-transform: uppercase;
  }

  .category {
    margin: 1.5625rem 0 1.0625rem;
    text-transform: uppercase;
  }

  .read-more {
    margin-bottom: 2.5rem;
    a {
      color: ${BRAND_PRIMARY};
      font-size: 0.75rem;
      letter-spacing: 0.07em;

      text-transform: uppercase;
      text-decoration: none;
      &:after {
        content: '❯';
        margin-left: 0.25rem;
      }
    }
  }
`

export default style
