import { css } from "@emotion/react";
import { lock } from "../utils/css";

const style = css`
  .topic {
    ${lock(11, 16, "font-size")}
    ${lock(13, 23, "line-height")}
    letter-spacing: 0.1em;
    margin-top: 1rem;
  }
  h3 {
    ${lock(20, 28, "font-size")}
    ${lock(23, 32, "line-height")}
    letter-spacing: -0.01em;
    margin: 1rem 0;
  }
  .description,
  .link {
    ${lock(13, 18, "font-size")}
    ${lock(17, 24, "line-height")}
  }
`;

export default style;
