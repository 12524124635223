import React from "react";

import PropTypes from "prop-types";
import Container from "./Container";
import Media from "./Media";
import Video from "./Video";

import style from "./Section2Col.styles";

const Section2Col = ({ title, content }) => (
  <div className="inner-section col-2" css={style}>
    {title && <h2>{title}</h2>}

    {content &&
      content.map(item => {
        return (
          <div className={`col ${item.__typename}`} key={item.id}>
            {item.__typename === "ContentfulContainer" ? (
              <Container
                title={item.title}
                topic={item.topic}
                cssClass={item.cssClass}
                content={item.content}
              />
            ) : null}

            {item.__typename === "ContentfulMedia" ? (
              <Media
                media={item.media}
                alt={item.media.description}
                cssClass={item.class}
              />
            ) : null}

            {item.__typename === "ContentfulVideo" ? (
              <Video
                url={item.videoUrl}
                description={item.description}
                cssClass="padded"
              />
            ) : null}
          </div>
        );
      })}
  </div>
);

// TODO: Change/simplify the component's interface if necessary (for example, if everything comes in through a
//       `section` or `data` prop)
Section2Col.defaultProps = {
  backgroundColor: "white"
};

Section2Col.propTypes = {
  backgroundColor: PropTypes.string
};

export default Section2Col;
