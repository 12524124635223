import React from "react";
import Img from "gatsby-image";

import PropTypes from "prop-types";

const Media = ({ alt, media, cssClass }) => {
  const { file, fluid, fixed, svg } = media;
  if (file?.contentType === "image/svg+xml") {
    if (svg?.content) {
      // Inlined SVGs
      return <div dangerouslySetInnerHTML={{ __html: svg.content }} className={cssClass} />;
    }

    // SVGs that can/should not be inlined
    return <img src={file.url} alt={alt} className={cssClass} loading="lazy" />;
  }

  if (fixed) {
    return <Img fixed={fixed} alt={alt} className={cssClass} />;
  }

  return <Img fluid={fluid} alt={alt} className={cssClass} />;
};

Media.defaultProps = {
  alt: ""
};

Media.propTypes = {
  media: PropTypes.object.isRequired,
  alt: PropTypes.string
};

export default Media;
