import React, { useState } from 'react'

import Img from 'gatsby-image/withIEPolyfill'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'

import PropTypes from 'prop-types'

import style from './Slider.styles'

const ArrowLeft = ({ disabled, onClick }) => (
  <div
    className={`arrow-container arrow-container--left${
      disabled ? ' arrow-container--disabled' : ''
    }`}
    onClick={onClick}
  >
    <img
      className="arrow"
      src={'/img/arrow-left-content.svg'}
      alt="Left arrow"
    />
  </div>
)

const ArrowRight = ({ disabled, onClick }) => (
  <div
    className={`arrow-container arrow-container--right${
      disabled ? ' arrow-container--disabled' : ''
    }`}
    onClick={onClick}
  >
    <img
      className={`arrow arrow--right${disabled ? ' arrow--disabled' : ''}`}
      src={'/img/arrow-right-content.svg'}
      alt="Right arrow"
    />
  </div>
)

const isExternalLink = (url) => {
  var res = url?.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  )
  return res !== null
}

const Slider = ({
  title,
  description,
  internalTitle,
  slides,
  loop,
  showNavigationDots,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0)

  const [sliderRef, slider] = useKeenSlider({
    mode: 'free-snap',
    initial: 0,
    loop,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    },
  })

  return (
    <div css={style}>
      <div className="slider-content padded">
        {title && (
          <h2
            className={`slider-title ${
              internalTitle && internalTitle.includes('perks')
                ? 'perks-title'
                : null
            }`}
          >
            {title}
          </h2>
        )}
        {description && (
          <div
            className={`slider-description ${
              internalTitle && internalTitle.includes('perks')
                ? 'perks-description'
                : null
            }`}
          >
            {renderRichText(description)}
          </div>
        )}
      </div>
      <div className="keen-slider" ref={sliderRef}>
        {slider && (
          <div className="arrows desktop">
            <ArrowLeft onClick={(e) => e.stopPropagation() || slider.prev()} />
            <ArrowRight onClick={(e) => e.stopPropagation() || slider.next()} />
          </div>
        )}
        {slider && (
          <div className="arrows mobile">
            <ArrowLeft onClick={(e) => e.stopPropagation() || slider.prev()} />
            <ArrowRight onClick={(e) => e.stopPropagation() || slider.next()} />
          </div>
        )}
        {slides &&
          slides.map((slide, i) => {
            const linkText = slide.linkText

            return (
              <div className="keen-slider__slide padded" key={`slide-${i}`}>
                <div className="slide-wrapper">
                  <div className="slide-image">
                    <Img
                      fluid={slide.media.fluid}
                      alt={slide.media.description}
                    />
                  </div>
                  <div className="slide-content">
                    <div className="slide-content-container">
                      <div className="label">{slide.label}</div>
                      <div className="title">{slide.title}</div>
                      <div className="content">
                        {slide.text && renderRichText(slide.text)}
                      </div>
                      {slide.linkUrl && (
                        <div className="link">
                          {isExternalLink(slide.linkUrl) ? (
                            <a
                              href={slide.linkUrl}
                              target="_blank"
                              rel="noreferrer nofollow"
                              title="Opens in new window"
                            >
                              {linkText ? linkText : slide.linkUrl}
                            </a>
                          ) : (
                            <a href={slide.linkUrl}>
                              {linkText ? linkText : slide.linkUrl}
                            </a>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
      </div>
      {slider && showNavigationDots && (
        <div
          className={`dots ${
            internalTitle && internalTitle.includes('perks') ? 'perks' : null
          }`}
        >
          {[...Array(slider.details().size).keys()].map((idx) => {
            return (
              <button
                key={idx}
                aria-label="Navigate slides"
                onClick={() => {
                  slider.moveToSlideRelative(idx)
                }}
                className={`dot ${currentSlide === idx ? 'active' : ''}`}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

Slider.defaultProps = {
  loop: true,
  showNavigationDots: true,
}

Slider.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      img: PropTypes.url,
      label: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      link: PropTypes.url,
      linkText: PropTypes.string,
    })
  ),
  loop: PropTypes.bool,
  showNavigationDots: PropTypes.bool,
}

export default Slider
