import React from 'react'

import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { INLINES } from '@contentful/rich-text-types'

import Media from './Media'
import styles from './Container.styles'
import PropTypes from 'prop-types'

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <a
          href={node.data.uri}
          target="_blank"
          rel="noreferrer nofollow"
          title="Opens in new window"
        >
          {children}
        </a>
      )
    },
  },
}

const Container = ({ title, topic, cssClass, textColor, content }) => (
  <div css={styles}>
    <div className={cssClass}>
      {topic && <h1 className="topic">{topic}</h1>}
      {title && <h2>{title}</h2>}
      {content &&
        content.map((containerItem) => {
          if (containerItem.__typename === 'ContentfulContainer') {
            return (
              <Container
                key={containerItem.id}
                title={containerItem.title}
                topic={containerItem.topic}
                cssClass={[containerItem.cssClass, 'inner-content'].join(' ')}
                content={containerItem.content}
              />
            )
          }
          return (
            <div key={containerItem.id}>
              {containerItem.media && (
                <Media
                  media={containerItem.media}
                  alt={containerItem.media.description}
                  cssClass={containerItem.class}
                />
              )}
              {containerItem.text && (
                <>
                  {containerItem.title && <h3>{containerItem.title}</h3>}
                  <div className={[containerItem.class, 'text'].join(' ')}>
                    {renderRichText(containerItem.text, options)}
                  </div>
                </>
              )}
              {containerItem.__typename === 'ContentfulLink' &&
                (containerItem.backgroundColor ||
                !containerItem.url.includes('http') ? (
                  <a
                    className={containerItem.class}
                    href={containerItem.url}
                    target={
                      containerItem.url && containerItem.url.includes('https')
                        ? '_blank'
                        : null
                    }
                    rel="noreferrer"
                    title="Opens in new window"
                    style={{
                      backgroundColor: `${containerItem.backgroundColor}`,
                      color: `${containerItem.textColor}`,
                    }}
                  >
                    {containerItem.title}
                  </a>
                ) : (
                  // TODO: add conditional to check if url is a mailto link
                  <a
                    href={containerItem.url}
                    className={containerItem.class}
                    target="_blank"
                    rel="noreferrer"
                    title="Opens in new window"
                    style={{
                      color: `${containerItem.textColor}`,
                    }}
                  >
                    {containerItem.title}
                  </a>
                ))}
            </div>
          )
        })}
    </div>
  </div>
)

Container.propTypes = {
  title: PropTypes.string,
  topic: PropTypes.string,
  content: PropTypes.object,
}
export default Container
