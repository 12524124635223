import React from 'react'

import Img from 'gatsby-image'

import { renderRichText } from 'gatsby-source-contentful/rich-text'
import styles from './Card.styles'

const Card = ({ title, label, media, text, linkText, linkUrl }) => (
  <div css={styles}>
    {media && <Img fluid={media.fluid} alt={media.description} />}
    {title && <h3>{title}</h3>}
    {text && <div className="description">{renderRichText(text)}</div>}
    <a
      href={linkUrl}
      className="link"
      target="_blank"
      rel="noreferrer"
      title="Opens in new window"
    >
      {linkText ? linkText : linkUrl}
    </a>
  </div>
)

export default Card
