import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import PageComponent from '../components/PageComponent'
import SEO from '../components/seo'
import {
  PledgeSection,
  StepsSection,
  LookingForwardSection,
} from '../styles/diversity.styles'

export default function Diversity({ data }) {
  const [showText, toggleShowText] = useState(false)

  function renderHeader() {
    const [section] = data.page.sections.filter(
      (section) => section.internalTitle === 'Section > Diversity > Main'
    )

    return (
      <section
        className={`${section.layout} ${section.class} ${section.style}`}
        style={{
          backgroundColor: `${section.backgroundColor}`,
        }}
      >
        <PageComponent
          type={section.__typename}
          title={section.title}
          content={section.content}
        />
      </section>
    )
  }

  function renderPledge() {
    const [section] = data.page.sections.filter(
      (section) => section.internalTitle === 'Section > Diversity > Our Pledge'
    )
    return (
      <PledgeSection
        className={`${section.class}`}
        style={{ backgroundColor: `${section.backgroundColor}` }}
      >
        <h2>{section.content[0].title}</h2>
        <div>
          <div className="pledge-intro">
            {renderRichText(section.content[0].content[0].text)}
          </div>
          {/* {showText && (
            <>
              {' '}
              <div className="three-dots">...</div>
              <div className="text-pledge">
                {renderRichText(section.content[0].content[1].text)}
              </div>
            </>
          )}
          <button
            onClick={() => toggleShowText(!showText)}
            className={showText && 'rotate'}
          >
            {!showText ? 'Read More' : 'Close'}
          </button> */}
        </div>
      </PledgeSection>
    )
  }

  // function renderSteps() {
  //   const [section] = data.page.sections.filter(
  //     (section) => section.internalTitle === 'Section > Diversity > Steps'
  //   )

  //   return (
  //     <StepsSection className={`${section.class}`}>
  //       <h2>{section.content[0].title}</h2>
  //       <div className="list-wrapper">
  //         {renderRichText(section.content[0].content[0].text)}
  //       </div>
  //     </StepsSection>
  //   )
  // }

  // function renderLookingForward() {
  //   const [section] = data.page.sections.filter(
  //     (section) => section.internalTitle === 'Section > Looking Forward'
  //   )
  //   return (
  //     <LookingForwardSection
  //       className={`${section.layout} ${section.class} ${section.style}`}
  //       style={{
  //         backgroundColor: `${section.backgroundColor}`,
  //       }}
  //     >
  //       <PageComponent
  //         type={section.__typename}
  //         title={section.title}
  //         content={section.content}
  //       />
  //     </LookingForwardSection>
  //   )
  // }

  return (
    <>
      <SEO
        title={data.page.title}
        description={data.page.seoDescription}
        bodyAttributes={{ class: 'diversity' }}
      />
      {renderHeader()}
      {renderPledge()}
      {/* {renderSteps()} */}
      {/* {renderLookingForward()} */}
    </>
  )
}

export const diversityPageQuery = graphql`
  query {
    page: contentfulPage(contentful_id: { eq: "wl6FAd2TBIp2qZt8YjFZt" }) {
      id
      title
      seoDescription
      sections {
        ... on ContentfulSection1Col {
          id
          class
          backgroundColor
          style
          internalTitle
          content {
            __typename
            ... on ContentfulContainer {
              id
              __typename
              internalTitle
              title
              topic
              content {
                ... on ContentfulText {
                  class
                  text {
                    raw
                  }
                }
                ... on ContentfulLink {
                  title
                  url
                  backgroundColor
                  class
                }
              }
            }
            ... on ContentfulMedia {
              id
              media {
                id
                description
                fluid(maxWidth: 1080, quality: 100) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
        ... on ContentfulSection2Col {
          __typename
          id
          internalTitle
          backgroundColor
          layout
          style
          class
          content {
            ... on ContentfulContainer {
              __typename
              title
              topic
              cssClass
              content {
                ... on ContentfulText {
                  class
                  text {
                    raw
                  }
                }
                ... on ContentfulLink {
                  __typename
                  title
                  url
                  backgroundColor
                  class
                }
              }
            }
            ... on ContentfulMedia {
              id
              media {
                description
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
            ... on ContentfulText {
              class
              text {
                raw
              }
            }
          }
        }
      }
    }
  }
`
