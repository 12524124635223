import { css } from '@emotion/react'

import { lock } from '../utils/css'
import { BRAND_PRIMARY, MQ } from '../design.styles'

const footer = css`
  background-color: ${BRAND_PRIMARY};
  color: #fff;
  font-family: 'Avenir Next Regular', Arial, Helvetica, sans-serif;
  padding: 1.75rem 2.5rem;

  ${MQ[2]} {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    gap: 1rem;
  }

.ot-sdk-show-settings {
    background: transparent !important;
    color: #fff !important;
    font-family: 'Avenir Next Regular', Arial, Helvetica, sans-serif;
    font-size: 1.5rem !important;
    border: none !important;
    padding: .015rem;
    margin-top: 2.0625rem;
    font-weight: 1500;
    letter-spacing: 0.03em;
    text-align: left;
}

  .footer-logo-div {
    max-width: 8.125rem;
    min-width: 7.5rem;
    height: 2.375rem;
    margin: auto 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .tagline {
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: left;
    ${MQ[2]} {
      display: none;
    }
  }

  .social,
  .social-desktop {
    margin-top: 2.0625rem;

    ${MQ[2]} {
      justify-content: flex-end;
      margin-top: 0;
    }

    a {
      margin-right: 1.375rem;
      &:last-of-type {
        margin-right: 0;
      }

      ${MQ[2]} {
        margin-right: 1.0625rem;
      }

      img {
        width: 1.6875rem;
        height: 1.6875rem;

        ${MQ[2]} {
          display: block;
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }
  }

  .social {
    display: flex;

    ${MQ[2]} {
      display: none;
    }
  }

  .social-desktop {
    display: none;

    ${MQ[2]} {
      display: flex;
      align-items: center;
    }
  }

  .links {
    margin-top: 2.0625rem;
    font-weight: 500;
    ${lock(18, 24, 'font-size')}
    ${lock(28, 36, 'line-height')}
    letter-spacing: 0.03em;
    text-align: left;
    ${MQ[2]} {
      min-width: 31.25rem;
      letter-spacing: unset;
      text-align: center;
    }

    .mobile {
      display: block;

      ${MQ[2]} {
        display: none;
      }

      a,
      a:-webkit-any-link {
        display: inline;
        margin-right: 1.25rem;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    .desktop {
      display: none;
      ${MQ[2]} {
        display: block;
      }
    }

    a {
      display: block;
      color: #fff;
      text-decoration: none;

      ${MQ[2]} {
        display: inline;
        margin-right: 1.25rem;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  text-align: center;
  letter-spacing: 0.02em;

  .copyright {
    ${lock(18, 22, 'font-size')}

    font-weight: bold;
    margin: 2.4375rem 0 0;
    line-height: 1rem;

    ${MQ[2]} {
      grid-column: 1 /4;
      justify-content: center;
    }
  }
`

export default { footer }
