import React from "react";
import styles from "./Video.styles.js";

const Video = ({ url, description, cssClass }) => {
  return (
    <div css={styles} className={cssClass}>
      <div className="video-wrapper">
        <iframe
          aria-label="Embedded video"
          title={description}
          src={url}
          frameBorder="0"
          allow="fullscreen; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  );
};

export default Video;
