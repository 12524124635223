import React from 'react'

import Header from '../components/Header'
import SubNav from '../components/SubNav'
import EmailSignup from '../components/EmailSignup'
import Footer from '../components/Footer'

import PropTypes from 'prop-types'

import { Global } from '@emotion/react'
import '../typography.css'
import './reset.css'

import globalStyle from './global.styles.js'
import style from './layout.styles.js'

// TODO: Specify whether the slider(s) should be visible via Contentful (and probably in another component) -
//       I added this here temporarily for demonstration purposes only
const Layout = ({ slider, pageContext, children, location }) => {
  return (
    <>
      <Global styles={globalStyle} />
      <div css={style}>
        <Header currentPage={location.pathname} />
        {pageContext.renderSubNav && <SubNav />}
        <main>{children}</main>

        {/* TODO/TEMPORARY: Pass real image URLs (from Contentful) to the component */}
        {/* {slider ? <InstagramSlider /> : null}
        {slider ? <Slider /> : null} */}
      </div>
      <div className="footer-container">
        {/* <EmailSignup /> */}
        <Footer currentPage={location.pathname} />
      </div>
    </>
  )
}

Layout.defaultProps = {
  slider: true,
}

Layout.propTypes = {
  slider: PropTypes.bool.isRequired,
}

export default Layout
