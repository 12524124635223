import { css } from "@emotion/react";

// -- Breakpoints --
// NOTE: we may want to add breakpoints as suggested by Emotion, see line 22 below
export const PHONE_SMALL_PORTRAIT = 320;
export const PHONE_LARGE_PORTRAIT = 375;

// iPhone X
export const phone = () =>
  `@media only screen and (max-device-width: ${PHONE_LARGE_PORTRAIT}px)`;
export const phoneLarge = () =>
  `@media only screen and (min-device-width: ${PHONE_SMALL_PORTRAIT +
    1}px) and (max-device-width: ${PHONE_LARGE_PORTRAIT}px)`;
// Desktop
export const desktop = () =>
  `@media (min-width: ${PHONE_LARGE_PORTRAIT + 1}px)`;

const breakpoints = [375, 576, 768, 992, 1280, 1920];
export const MQ = breakpoints.map(bp => `@media (min-width: ${bp}px)`);

export const SITE_MOBILE_WIDTH = 576;
export const SITE_MAXWIDTH = 1200;

// -- Colors --
export const BRAND_PRIMARY = "#fc3f31";

export const BRAND_BLACK = "#101820";
export const BRAND_GREEN = "#9eccbf";
export const BRAND_BLUE = "#33a3b0";

// -- Sliders --
/*
Based on the following requirements from Kristina:

Largest size (> 1920px) - 6 slides
> 576px - 1920px - 5 slides
< 576px - 4 slides

TODO/Possible improvements: create/use design system constants for the breakpoints and turn the const into a function
to allow the caller to change the number of slides per view to display
*/
export const sliderBreakpoints = {
  [`(max-width: 576px)`]: {
    slidesPerView: 4,
    mode: "free-snap"
  },
  [`(min-width: 577px) and (max-width: 1920px)`]: {
    slidesPerView: 5,
    mode: "free-snap"
  },
  [`(min-width: 1921px)`]: {
    slidesPerView: 6,
    mode: "free-snap"
  }
};

// -- Utility functions
export const centerVertically = css`
  position: absolute;

  top: 50%;
  transform: translateY(-50%);
`;

export const mobileOnly = (display = "block") => css`
  display: ${display};

  ${desktop()} {
    display: none;
  }
`;

export const desktopOnly = (display = "block") => css`
  display: none;

  ${desktop()} {
    display: ${display};
  }
`;
