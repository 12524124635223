import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'

import style from './JobListing.styles'

const JOBS_URL = `${process.env.GATSBY_JOBS_URL_PREFIX || ''}/${
  process.env.GATSBY_JOBS_URL_PATH || 'api/jobs'
}`

const JobListing = ({ title }) => {
  const [jobs, setJobs] = useState([])

  useEffect(() => {
    const getJobs = async () => {
      try {
        const jobs = await (await fetch(JOBS_URL)).json()

        // These are already ordered by date (Most recently posted jobs first)
        const kinshipJobs = jobs.items.filter(
          (job) => job.company.slug === 'kinship'
        )

        setJobs(kinshipJobs)
      } catch (err) {
        // TODO
        console.error('[Error]', err)
      }
    }

    getJobs()
  }, [])

  return (
    <div css={style}>
      <div className="title">{title}</div>
      {jobs.length ? (
        <div className="jobs">
          {jobs.map((job) => (
            <div className="job" key={job.id}>
              <div className="jobTitle">
                <a href={job.url}>{job.title}</a>
              </div>
              <div className="location">{job.locations[0]}</div>
            </div>
          ))}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  )
}

JobListing.defaultProps = {
  title: 'Ready to join us?',
}

JobListing.propTypes = {
  title: PropTypes.string.isRequired,
}

export default JobListing
