import { css } from '@emotion/react'

import { lock } from '../utils/css'
import { MQ, BRAND_BLACK } from '../design.styles'

const style = css`
  display: flex;

  flex-direction: column;

  @media (min-width: 36rem) {
    flex-direction: row;
  }

  /* Components should always take up 100% of the available width */
  width: 100%;
  /* Vary the component's min-height between 350px @ 1200px browser width and 250px @ 576px */
  ${lock(250, 350, 'min-height')}

  .content {
    flex-basis: 100%;

    /* Remember... This is based on the parent element's width */
    padding: 5%;

    font-family: 'Avenir Next Regular', Arial, Helvetica, sans-serif;

    color: ${BRAND_BLACK};

    letter-spacing: -0.02em;

    @media (min-width: 36rem) {
      flex-basis: auto;
    }

    @media (min-width: 75rem) {
      flex-basis: 66%;
    }

    .title {
      font-weight: 500;

      ${lock(24, 40, 'font-size')}
      ${lock(28, 44, 'line-height')}
    }

    .text {
      ${lock(18, 26, 'font-size')}
      ${lock(22, 32, 'line-height')}

      margin-top: 0.75rem;

      @media (min-width: 75rem) {
        margin-top: 1.875rem;
      }
    }

    .link {
      font-weight: 500;

      ${lock(16.5, 25, 'font-size')}
      ${lock(20, 30, 'line-height')}

      margin-top: 1.5rem;

      ${MQ[1]} {
        margin-top: 2.5rem;
      }

      a {
        color: #fff;

        text-decoration: none;
      }

      a::after {
        display: inline-block;
        content: ' ';
        height: 0.9375rem;
        width: 1.125rem;
        margin-left: 0.3125rem;
        mask: url('img/arrow-long.svg') no-repeat 50% 50%;
        -webkit-mask: url('img/arrow-long.svg') no-repeat 50% 50%;
        -webkit-mask-size: cover;
        mask-size: contain;
        background-color: currentColor;
      }
    }
  }

  .image {
    position: relative;

    flex-basis: 100%;

    @media (min-width: 36rem) {
      flex-basis: 50%;
    }

    @media (min-width: 75rem) {
      flex-basis: 33%;
    }

    img {
      /* Make sure the image is flush with the bottom of the container... */
      display: block;

      position: relative;

      /*
        -- Image --

        Intrinsic dimensions: 420px * 399px
        Aspect ratio: 1.0526315789:1 (I'm not sure if this is relevant...)

        @ 1200px, the image extends 49px above the container, so... The image height to container height ratio
        is 350 / 399 = 1.14:1 so... If the container's smallest height (@576px browser width) is 250px, then the
        image's smallest height @576px browser width should be 250 * 1.14 = 285px
      */
      ${lock(285, 399, 'max-height')}

      margin-right: 0;
      margin-left: auto;

      @media (min-width: 36rem) {
        /* At the desktop breakpoint, the image needs to be positioned absolutely in the bottom right hand corner of its container */
        position: absolute;

        bottom: 0;
        right: 0;
      }
    }
  }
`

export default style
