import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import PageComponent from '../components/PageComponent'
import SEO from '../components/seo'

export default function About() {
  const data = useStaticQuery(graphql`
    {
      page: contentfulPage(contentful_id: { eq: "7yWGe3QZlPOSpLij4F0mnf" }) {
        id
        title
        sections {
          ... on ContentfulSection1Col {
            id
            class
            backgroundColor
            style
            content {
              __typename
              ... on ContentfulContainer {
                id
                __typename
                internalTitle
                title
                topic
                cssClass
                content {
                  ... on ContentfulText {
                    class
                    text {
                      raw
                    }
                  }
                  ... on ContentfulLink {
                    title
                    url
                    backgroundColor
                    class
                  }
                }
              }
              ... on ContentfulMedia {
                id
                media {
                  id
                  description
                  fluid(maxWidth: 1080, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
            }
          }
          ... on ContentfulSection2Col {
            __typename
            id
            backgroundColor
            layout
            style
            class
            content {
              ... on ContentfulContainer {
                __typename
                title
                topic
                cssClass
                content {
                  ... on ContentfulText {
                    class
                    text {
                      raw
                    }
                  }
                  ... on ContentfulLink {
                    __typename
                    title
                    url
                    backgroundColor
                    class
                  }
                }
              }
              ... on ContentfulVideo {
                id
                videoUrl
                description
              }
              ... on ContentfulMedia {
                id
                class
                media {
                  description
                  fluid(maxWidth: 800, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
              ... on ContentfulText {
                class
                text {
                  raw
                }
              }
            }
          }
          ... on ContentfulSection3Col {
            id
            title
            class
            label
            style
            backgroundColor
            content {
              ... on Node {
                # ... on ContentfulContainer {
                #   __typename
                #   id
                #   title
                #   cssClass
                #   content {
                #     ... on ContentfulText {
                #       title
                #       class
                #       text {
                #         raw
                #       }
                #     }
                #     ... on ContentfulLink {
                #       __typename
                #       title
                #       url
                #       backgroundColor
                #       class
                #     }
                #     ... on ContentfulMedia {
                #       id
                #       class
                #       media {
                #         description
                #         fixed {
                #           ...GatsbyContentfulFixed_withWebp
                #         }
                #       }
                #     }
                #   }
                # }
                # ... on ContentfulArticle {
                #   __typename
                #   title
                #   slug
                #   category
                #   hero {
                #     description
                #     fluid(maxWidth: 800, quality: 100) {
                #       ...GatsbyContentfulFluid_withWebp
                #     }
                #   }
                # }
                ... on ContentfulSlide {
                  id
                  title
                  linkUrl
                  linkText
                  text {
                    raw
                  }
                  media {
                    description
                    fluid(maxWidth: 400, quality: 100) {
                      ...GatsbyContentfulFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <>
      <SEO
        title={data.page.title}
        description={data.page.seoDescription}
        bodyAttributes={{ class: 'about' }}
      />

      {data.page.sections.map((section) => {
        return (
          <section
            key={section.id}
            className={[section.layout, section.class, section.style].join(' ')}
            style={{
              backgroundColor: `${section.backgroundColor}`,
            }}
          >
            <PageComponent
              type={section.__typename}
              title={section.title}
              content={section.content}
              slides={section.slides}
            />
          </section>
        )
      })}
    </>
  )
}
