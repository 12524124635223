import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import SEO from '../components/seo'
import PageComponent from '../components/PageComponent'

export default function Homepage() {
  const data = useStaticQuery(graphql`
    {
      page: contentfulPage(contentful_id: { eq: "6rAu8Snmri7piCiDUoOp6d" }) {
        id
        title
        seoDescription
        sections {
          ... on ContentfulSection1Col {
            id
            class
            backgroundColor
            style
            content {
              __typename
              ... on ContentfulContainer {
                id
                __typename
                internalTitle
                title
                topic
                cssClass
                content {
                  ... on ContentfulText {
                    class
                    text {
                      raw
                    }
                  }
                  ... on ContentfulLink {
                    title
                    url
                    backgroundColor
                    class
                  }
                }
              }
              ... on ContentfulMedia {
                id
                media {
                  id
                  description
                  fluid(maxWidth: 1080, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
            }
          }
          ... on ContentfulSection2Col {
            __typename
            id
            backgroundColor
            layout
            style
            class
            content {
              ... on ContentfulContainer {
                __typename
                title
                topic
                cssClass
                content {
                  ... on ContentfulText {
                    class
                    text {
                      raw
                    }
                  }
                  ... on ContentfulLink {
                    __typename
                    title
                    url
                    backgroundColor
                    class
                  }
                }
              }
              ... on ContentfulMedia {
                id
                media {
                  description
                  fluid(maxWidth: 800, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
              ... on ContentfulText {
                class
                text {
                  raw
                }
              }
            }
          }
          ... on ContentfulSlider {
            __typename
            id
            title
            class
            description {
              raw
            }
            backgroundColor
            slides {
              title
              linkUrl
              linkText
              text {
                raw
              }
              media {
                description
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <>
      <SEO
        title={data.page.title}
        description={data.page.seoDescription}
        bodyAttributes={{ class: 'home' }}
      />
      <h1 className="hide-visually">{data.page.title}</h1>

      {data.page.sections.map((section) => {
        return (
          <section
            key={section.id}
            // TODO: check if class is set in Conttentful to avoid 'undefined' as class
            className={`${section.layout} ${section.class} ${section.style}`}
            style={{
              backgroundColor: `${section.backgroundColor}`,
            }}
          >
            <PageComponent
              type={section.__typename}
              title={section.title}
              label={section.label}
              content={section.content}
              slides={section.slides}
              bgColor={section.backgroundColor}
              description={section.description}
            />
          </section>
        )
      })}
    </>
  )
}
