import styled from "@emotion/styled";

import { BRAND_BLACK, BRAND_PRIMARY, MQ } from "../design.styles";
import { lock } from "../utils/css";

export const SubNavCSS = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  font-family: "Avenir Next Regular", Arial, Helvetica, sans-serif;
  background-color: ${({ addBgColor }) => addBgColor && "#f8f9f8"};

  ${MQ[1]} {
    flex-direction: row;
    justify-content: flex-start;
  }

  a[aria-current="page"] {
    color: ${BRAND_PRIMARY};
  }

  #header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    border: none;
    font-family: "Avenir Next Regular", Arial, sans-serif;
    padding-left: 0;

    p {
      font-size: 1rem;
    }

    #arrow {
      background: transparent;
      cursor: pointer;
    }

    .rotate {
      transition: transform ease-in-out 0.4s;
      transform: rotate(90deg);
    }

    .no-rotate {
      transition: transform ease-in-out 0.4s;
      transform: rotate(-90deg);
    }

    ${MQ[1]} {
      display: none;
    }
  }

  #collapse {
    overflow: hidden;
    transition: max-height 0.6s ease;

    a {
      margin: 1rem 0;
      text-decoration: none;
      display: block;
      transition: color 0.3s ease-in;

      &:hover {
        cursor: pointer;
        color: ${BRAND_PRIMARY};
      }

      :first-of-type {
        margin-top: 0;
      }

      :last-of-type {
        margin-bottom: 0;
      }
    }

    ${MQ[1]} {
      display: none;
    }
  }

  #inline-nav {
    display: none;

    ${MQ[1]} {
      display: flex;
      justify-content: space-between;
      padding: 0;
      width: 90%;
      ${lock(12, 16, "font-size")}

      a {
        text-decoration: none;
        transition: color 0.2s ease-in;
      }

      a:hover {
        color: ${BRAND_PRIMARY};
        cursor: pointer;
      }

      #bold {
        font-weight: 600;
        color: ${BRAND_BLACK};

        &:hover {
          color: ${BRAND_BLACK};
        }
      }
    }

    ${MQ[2]} {
      width: 80%;
    }
  }
`;
