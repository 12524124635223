import { css } from "@emotion/react";
import { BRAND_PRIMARY, MQ } from "../design.styles";
import { lock } from "../utils/css";

const style = css`
  .topic {color: #000}
  .topic-hilite .topic {
    color: ${BRAND_PRIMARY};
  }

  .centered {
    text-align: center;
    ${MQ[1]} {
      text-align: left;
    }
  }

  .mars h2 {
    ${lock(24, 40, "font-size")}
    ${lock(
      28,
      44,
      "line-height"
    )}  
    font-family: "Avenir Next Medium", Arial, sans-serif;

    font-weight: 500;
    letter-spacing: -0.02em;
    text-transform: inherit;
  }

  .inclusivity-text a {
    text-decoration: none;
    &:after {
      content: "	➔";
    }
  }
`;

export default style;
