import { css } from '@emotion/react'

import { MQ } from '../design.styles'

const globalStyle = css`
  // 404 page
  body.notFound {
    background-size: cover;
    background-repeat: no-repeat;

    background-position: 45% 0%;

    /* 576px */
    ${MQ[1]} {
      background-position: 40% 0%;
    }

    ${MQ[3]} {
      background-position: 20% 0%;
    }

    /* 1200px */
    ${MQ[4]} {
      background-position: 0 0;
    }
  }

  .sr-only {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    white-space: nowrap;
  }

  .topnav-link {
    text-decoration: none;
  }
`

export default globalStyle
