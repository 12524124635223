import React from 'react'

import { Link, useStaticQuery, graphql } from 'gatsby'

import { mobileOnly } from '../design.styles'
import styles from './Footer.styles.js'

const Footer = ({ currentPage }) => {
  const { whiteLogo, techBlogLogo } = useStaticQuery(graphql`
    query {
      whiteLogo: contentfulAsset(title: { eq: "Kinship Logo White" }) {
        title
        svg {
          content
        }
      }
      techBlogLogo: contentfulAsset(title: { eq: "KinshipTechBlog white" }) {
        title
        svg {
          content
        }
      }
    }
  `)

  return (
    <footer css={styles.footer}>
      <div
        className="footer-logo-div"
        dangerouslySetInnerHTML={{
          __html: currentPage.includes('/techblog')
            ? techBlogLogo.svg.content
            : whiteLogo.svg.content,
        }}
      />
      <div className="tagline">Taking pet care to the next level.</div>
      <div className="social" css={mobileOnly()}>
        <a
          href="https://instagram.com/kinshipco"
          rel="noreferrer"
          target="_blank"
          title="Opens in new window"
        >
          <img src="/img/social-instagram.svg" alt="Instagram" />
        </a>
        <a
          href="https://twitter.com/kinshipco"
          rel="noreferrer"
          target="_blank"
          title="Opens in new window"
        >
          <img src="/img/social-twitter.svg" alt="Twitter" />
        </a>
        <a
          href="https://facebook.com/KinshipCo"
          rel="noreferrer"
          target="_blank"
          title="Opens in new window"
        >
          <img src="/img/social-facebook.svg" alt="Facebook" />
        </a>
        <a
          href="https://linkedin.com/company/kinshipco"
          rel="noreferrer"
          target="_blank"
          title="Opens in new window"
        >
          <img src="/img/social-linkedin.svg" alt="LinkedIn" />
        </a>
      </div>
      <div className="links">
        <a href="mailto:communications@kinship.co">Contact Us</a>

        <a
          href="https://www.mars.com/legal/ca-supply-chain-act"
          target="_blank"
          rel="noreferrer"
          title="Opens in new window"
        >
          CA Supply Chain Act
        </a>
        <a
          href="https://www.mars.com/modern-slavery-act"
          target="_blank"
          rel="noreferrer"
          title="Opens in new window"
        >
          Modern Slavery Act
        </a>
        <Link to="/diversity">Inclusivity & Diversity</Link>
        {/* <Link to="/techblog">Tech Blog</Link> */}
        <div className="mobile">
          <a
            href="https://www.mars.com/privacy"
            target="_blank"
            rel="noreferrer"
            title="Opens in new window"
          >
            Privacy
          </a>
          <a
            href="https://www.mars.com/legal"
            target="_blank"
            rel="noreferrer"
            title="Opens in new window"
          >
            Legal
          </a>
          <a
            href="https://www.mars.com/cookies-english"
            target="_blank"
            rel="noreferrer"
            title="Opens in new window"
          >
            Cookie Notice
          </a>
          <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</button>
          <a
            href="https://www.mars.com/accessibility"
            target="_blank"
            rel="noreferrer"
            title="Opens in new window"
          >
            Accessibility
          </a>
        </div>
        <div className="desktop">
          <a
            href="https://www.mars.com/privacy"
            target="_blank"
            rel="noreferrer"
            title="Opens in new window"
          >
            Privacy
          </a>
          <a
            href="https://www.mars.com/mars-incorporated-adchoices-united-states"
            target="_blank"
            rel="noreferrer"
            title="Opens in new window"
          >
            AdChoices
          </a>
          <a
            href="https://www.mars.com/legal"
            target="_blank"
            rel="noreferrer"
            title="Opens in new window"
          >
            Legal
          </a>
          <a
            href="https://www.mars.com/cookies-english"
            target="_blank"
            rel="noreferrer"
            title="Opens in new window"
          >
            Cookie Notice
          </a>
          <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</button>
          <a
            href="https://www.mars.com/accessibility"
            target="_blank"
            rel="noreferrer"
            title="Opens in new window"
          >
            Accessibility
          </a>
          <a
            href="https://privacyportal-eu.onetrust.com/webform/5f2759ff-9efe-412c-a3be-f13092d2c945/98373dac-6247-48a6-a190-f0094af43ee8"
            target="_blank"
            rel="noreferrer"
            title="Opens in new window"
          >
            Your Privacy Choices&nbsp;
          <img src="/img/privacyoptions.svg" alt="Privacy Choices" width="40" height="20"/>
          </a>
        </div>
      </div>
      <div className="social-desktop">
        <a
          href="https://instagram.com/kinshipco"
          rel="noreferrer"
          target="_blank"
          title="Opens in new window"
        >
          <img src="/img/social-instagram.svg" alt="Instagram" />
        </a>
        <a
          href="https://twitter.com/kinshipco"
          rel="noreferrer"
          target="_blank"
          title="Opens in new window"
        >
          <img src="/img/social-twitter.svg" alt="Twitter" />
        </a>
        <a
          href="https://facebook.com/KinshipCo"
          rel="noreferrer"
          target="_blank"
          title="Opens in new window"
        >
          <img src="/img/social-facebook.svg" alt="Facebook" />
        </a>
        <a
          href="https://linkedin.com/company/kinshipco"
          rel="noreferrer"
          target="_blank"
          title="Opens in new window"
        >
          <img src="/img/social-linkedin.svg" alt="LinkedIn" />
        </a>
      </div>
      <div className="copyright">
        &#169; {new Date().getFullYear()} Kinship Partners Inc., an affiliate of Mars Incorporated.
      </div>
    </footer>
  )
}

export default Footer
