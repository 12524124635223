import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'

import PageComponent from '../components/PageComponent'
import SEO from '../components/seo'

export default function Careers() {
  const data = useStaticQuery(graphql`
    {
      page: contentfulPage(contentful_id: { eq: "2a1iYqxt6VNzFgSsJc4B7D" }) {
        id
        title
        sections {
          ... on ContentfulSection1Col {
            id
            class
            backgroundColor
            style
            title
            content {
              ... on ContentfulContainer {
                id
                __typename
                title
                topic
                cssClass
                content {
                  ... on ContentfulText {
                    id
                    class
                    title
                    text {
                      raw
                    }
                  }
                  ... on ContentfulLink {
                    title
                    url
                    backgroundColor
                    class
                    textColor
                  }
                }
              }
            }
          }
          ... on ContentfulSection2Col {
            __typename
            id
            backgroundColor
            layout
            class
            content {
              ... on ContentfulContainer {
                id
                __typename
                title
                topic
                cssClass
                content {
                  ... on ContentfulContainer {
                    content {
                      ... on ContentfulMedia {
                        id
                        media {
                          id
                          description
                          fluid(maxWidth: 800, quality: 100) {
                            ...GatsbyContentfulFluid_withWebp
                          }
                          file {
                            contentType
                            url
                          }
                        }
                      }
                      ... on ContentfulText {
                        id
                        class
                        text {
                          raw
                        }
                      }
                    }
                  }
                  ... on ContentfulText {
                    id
                    class
                    text {
                      raw
                    }
                  }
                  ... on ContentfulLink {
                    id
                    title
                    url
                    backgroundColor
                    class
                  }
                }
              }
              ... on ContentfulMedia {
                id
                media {
                  id
                  description
                  fluid(maxWidth: 800, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
              ... on ContentfulVideo {
                id
                videoUrl
                description
              }
            }
          }
          ... on ContentfulSlider {
            __typename
            id
            title
            internalTitle
            description {
              raw
            }
            slides {
              id
              title
              text {
                raw
              }
              media {
                description
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO
        title={data.page.title}
        description={data.page.seoDescription}
        bodyAttributes={{ class: 'careers' }}
      />
      <h1 className="hide-visually">{data.page.title}</h1>

      {data.page.sections.map((section) => {
        return (
          <section
            key={section.id}
            className={[section.layout, section.class, section.style].join(' ')}
            style={{
              backgroundColor: `${section.backgroundColor}`,
            }}
          >
            <PageComponent
              type={section.__typename}
              title={section.title}
              description={section.description}
              internalTitle={section.internalTitle}
              content={section.content}
              slides={section.slides}
            />
          </section>
        )
      })}
    </>
  )
}
