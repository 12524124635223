import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";

import SEO from "../components/seo";

import style from "../styles/404.styles";

const NotFoundPage = () => {
  // naughty-dog.png: is 4737 * 2193px
  const { background } = useStaticQuery(graphql`
    {
      background: file(relativePath: { eq: "naughty-dog.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2592) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const imageData = background.childImageSharp.fluid;

  return (
    <div css={style}>
      <SEO
        title="Page not found"
        bodyAttributes={{
          class: "notFound"
        }}
        style={`body { background-image: url(${imageData.src}); }`}
      />
      <div className="content padded">
        <div className="title">We dropped the ball</div>
        <h1>This page isn't working. Our bad.</h1>
        <div className="message">
          Refresh and if there’s still a problem let our support pros know.
        </div>
        <Link to="/" role="button">
          Return home
        </Link>
      </div>
      <div className="no-content" />
    </div>
  );
};

export default NotFoundPage;
