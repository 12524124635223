import React from 'react'

import PropTypes from 'prop-types'

// Generic content type components
import Section1Col from './Section1Col' // "ContentfulSection1Col"
import Section2Col from './Section2Col' // "ContentfulSection2Col"
import Section3Col from './Section3Col'

import Slider from './Slider' // "ContentfulSlider"
import JobListing from './JobListing' // "ContentfulJobListing"

// Custom content type components (Used when data needs to be displayed in a non-standard way)
import ImgAboveTop from './ImgAboveTop' // img-above-top
// import Sliver from "./Sliver"; // sliver // TODO - Should this be a generic component?

const PageComponent = ({ type, layout, ...other }) => {
  const COMPONENTS_GENERIC = [
    'ContentfulSection1Col',
    'ContentfulSection2Col',
    'ContentfulSection3Col',
    'ContentfulSlider',
    'ContentfulJobListing',
  ]
  const components = {
    // -- Generic --
    ContentfulSection1Col: Section1Col,
    ContentfulSection2Col: Section2Col,
    ContentfulSection3Col: Section3Col,
    ContentfulSlider: Slider,
    ContentfulJobListing: JobListing,
    // -- Custom --
    'img-above-top': ImgAboveTop,
    // instagram: InstagramSlider
    // sliver: Sliver
  }

  let TagName

  if (COMPONENTS_GENERIC.includes(type)) {
    TagName = components[type]
  } else if (type === 'ContentfulCustom') {
    TagName = components[layout]
  }

  // The default, if a match isn't found, is ContentfulSection1Col
  TagName = TagName || components['ContentfulSection1Col']

  return <TagName {...other} />
}

PageComponent.defaultProps = {
  type: 'ContentfulSection1Col',
  layout: '',
}

PageComponent.propTypes = {
  type: PropTypes.string.isRequired,
  layout: PropTypes.string,
}

export default PageComponent
