import { css } from '@emotion/react'

import { SITE_MOBILE_WIDTH, SITE_MAXWIDTH } from '../design.styles'

export const lock = (
  min,
  max,
  cssProperty = 'font-size',
  minBreakpoint = SITE_MOBILE_WIDTH,
  maxBreakpoint = SITE_MAXWIDTH
) => {
  // y (Font size or whatever the cssProperty is) = mx + b
  const m = (max - min) / (maxBreakpoint - minBreakpoint)
  const b = min - m * minBreakpoint

  return css`
    ${cssProperty}: ${min / 16}rem;

    @media (min-width: ${minBreakpoint}px) {
      ${cssProperty}: calc(${m * 100}vw + ${b / 16}rem);
    }

    @media (min-width: ${maxBreakpoint}px) {
      ${cssProperty}: ${max / 16}rem;
    }
  `
}
