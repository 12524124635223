import { css } from '@emotion/react'

import { lock } from '../utils/css'
import { BRAND_PRIMARY, BRAND_BLACK, SITE_MAXWIDTH, MQ } from '../design.styles'

const layout = css`
  display: flex;
  flex-direction: column;

  margin: 0 auto;
  max-width: ${SITE_MAXWIDTH}px;

  // Use this class to add the default margins
  // to page elements that need it

  .padded {
    padding: 1.8125rem;

    // bp 576px
    ${MQ[1]} {
      padding: 0.6875rem 1.8125rem;
    }

    // bp 768px
    ${MQ[2]} {
      padding: 1.3125rem 2.375rem;
    }

    // bp 992px
    ${MQ[3]} {
      padding: 1.4375rem 3.125rem;
    }

    // bp 1200px
    ${MQ[4]} {
      padding: 1.5625rem 3.75rem;
    }
    // bp 1920px
    ${MQ[5]} {
      padding: 2.5rem 6rem;
    }
  }

  .header-logo-div {
    max-width: 8.125rem;
    min-width: 7.5rem;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  main {
    flex: 1;
  }

  h2,
  h3,
  h4 {
    ${lock(26, 54, 'margin-top')}
    font-family: "Avenir Next Medium", Arial, sans-serif;
    font-weight: 500;
  }

  h1,
  h2,
  h3,
  h4 {
    margin-bottom: 0;
  }

  h1 {
    ${lock(14, 30, 'margin-top')}

    color: ${BRAND_PRIMARY};
    font-family: 'Avenir Next Regular', Arial, sans-serif;
    ${lock(30, 63, 'font-size')} /* 30.1188?! */
    ${lock(33, 70, 'line-height')}
    
   letter-spacing: -0.5px;
  }
  h2 {
    ${lock(15, 32, 'font-size')} /* 15.2985?! */
    ${lock(17, 36, 'line-height')}
  }
  h3 {
    ${lock(13, 28, 'font-size')} /* 13.3862?! */
    ${lock(15, 32, 'line-height')}

    font-size: 1.1875rem;
    line-height: 2rem;

    letter-spacing: 0;
  }
  h4 {
    ${lock(12, 25, 'font-size')} /* 11.9519?! */
   ${lock(14, 30, 'line-height')}

    letter-spacing: 0;
  }

  h1.hide-visually {
    font-size: 0;
    width: 1px;
    height: 1px;
    display: inline-block;
    overflow: hidden;
    position: absolute !important;
    border: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    clip: rect(1px, 1px, 1px, 1px);
  }

  .page-main.home {
    position: relative;
  }

  .page-main .col-2 h2,
  .page-main.home h2 {
    ${lock(36, 61, 'font-size')}
    ${lock(43, 68, 'line-height')}
    font-family: "Avenir Next Regular", Arial, sans-serif;
    letter-spacing: -0.03em;
    margin-top: 1rem;
    color: ${BRAND_PRIMARY};
    text-transform: unset;
  }

  ${MQ[2]} {
    .page-main.home h2 {
      max-width: 40%;
      position: absolute;
      left: 2.375rem;
      top: 1.3125rem;
      z-index: 10;
    }
  }

  a {
    color: ${BRAND_BLACK};
  }

  .col-2 h2 {
    ${lock(12, 16, 'font-size')}
    ${lock(17, 22, 'line-height')}
    font-family: "Avenir Next Demi", Arial, sans-serif;
    letter-spacing: 0.07em;
    text-transform: uppercase;
  }

  .col-2 .text {
    ${lock(12, 39, 'font-size')}
    ${lock(24, 28, 'line-height')}
    font-family: "Avenir Next Regular", Arial, sans-serif;
    letter-spacing: -0.02em;
  }

  ${MQ[1]} {
    .col-2 {
      display: flex;
      flex-direction: row;
    }

    .col-2 > div {
      width: 50%;
    }

    // if layout is set to img-left
    .img-left .col-2 {
      flex-direction: row-reverse;
    }
  }

  .btn {
    border-radius: 31px;
    color: #fff;
    display: inline-block;
    font-size: 1.5rem;
    letter-spacing: 0.02em;
    padding: 0.75rem 2rem;
    text-decoration: none;
  }

  a.red {
    color: #c13e1a;
    text-decoration: none;
    fill: green;
  }
  a.arrow::after {
    display: inline-block;
    content: ' ';
    height: 0.75rem;
    width: 0.9375rem;
    margin-left: 0.3125rem;
    mask: url('img/arrow-long.svg') no-repeat 50% 50%;
    -webkit-mask: url('img/arrow-long.svg') no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: contain;
    background-color: currentColor;
  }

  .topic {
    ${lock(12, 16, 'font-size')}
    ${lock(17, 36, 'line-height')}
    font-family: "Avenir Next Demi", Arial, sans-serif;
    letter-spacing: 0.07em;
    margin: 0;
    text-transform: uppercase;
  }

  // Sections

  .section-resume {
    margin: 2rem 0;
  }

  .section-resume h2 {
    ${lock(24, 39, 'font-size')}
    ${lock(28, 43, 'line-height')}
    color: #fc3f31;
    margin-top: 1rem;
    text-align: center;
  }

  section {
    font-family: 'Avenir Next Regular', Arial, sans-serif;

    .col .text {
      ${lock(18, 26, 'font-size')}
      ${lock(22, 32, 'line-height')}
    }
  }

  .inner-section .flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    div {
      text-align: center;
    }
    & > div {
      width: 50%;
      ${MQ[1]} {
        width: inherit;
      }
    }
  }

  .col-2 .topic + h3 {
    margin-top: 0;
  }

  // keep image at the bottom of the section
  .col.ContentfulMedia {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 3rem;
    margin-top: 2rem;
  }

  .rounded-corner-right {
    border-bottom-right-radius: 40px;
    ${MQ[1]} {
      border-bottom-right-radius: 100px;
    }
  }

  .rounded-corner-left {
    border-bottom-left-radius: 40px;
    ${MQ[1]} {
      border-bottom-left-radius: 100px;
    }
  }

  .teal.colored-text p,
  .teal.colored-text a {
    color: white;
  }

  .section-invest div {
    text-align: center;
    ${MQ[1]} {
      text-align: left;
    }
    h3 {
      font-family: 'Avenir Next Demi', Arial, sans-serif;
      font-size: 0.75rem;
      font-weight: 600;
      margin: 0;
      text-transform: uppercase;
    }
  }

  .section-invest .leap,
  .section-invest .companion {
    h3 {
      display: none;
    }
    .text {
      ${lock(15, 17, 'font-size')}
      ${lock(19, 23, 'line-height')}
    }
  }

  .section-invest .intro {
    .text {
      ${lock(16, 20, 'font-size')}
      ${lock(20, 27, 'line-height')}
    }
    h2 {
      ${lock(12, 16, 'font-size')}
      ${lock(17, 22, 'line-height')}   
      font-family: "Avenir Next Demi", Arial, sans-serif;
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }
  ${MQ[1]} {
    .section-invest .intro {
      border-top: 0;
      padding-top: 0;
      h2 {
        height: 2.125rem !important;
        text-align: left;
      }
    }
  }

  .section-invest .logo-leap {
    height: 2.125rem !important;
    max-width: 7.75rem;
  }

  .section-invest .logo-companion {
    height: 2.125rem !important;
    max-width: 11.375rem !important;

    img {
      object-fit: contain !important;
    }
  }

  .section-invest .padded div p {
    margin: 0.25rem 0;
  }

  .section-invest .col-3 {
    .col {
      margin: 32px auto;
      max-width: 70%;
    }

    ${MQ[1]} {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem;
      .col {
        max-width: 100%;
      }
    }
  }

  .inclusivity-text {
    margin-right: 1rem;
  }

  .section-lets-talk .inner-section {
    display: flex;
    flex-direction: row;
    .ContentfulContainer {
      width: 70%;
    }

    .ContentfulMedia {
      width: 30%;
    }

    .gatsby-image-wrapper {
      width: 8.125rem;
      ${MQ[2]} {
        width: 16.5625rem;
      }
    }
  }

  /* .section-offices h2 {
    ${lock(24, 40, 'font-size')}
    ${lock(28, 44, 'line-height')}   
    text-align: center;
  }

  .section-offices h3 {
    ${lock(16, 22, 'font-size')}
    ${lock(20, 26, 'line-height')}
  }
  .section-offices .text {
    ${lock(16, 22, 'font-size')}
    ${lock(20, 26, 'line-height')}
    color: #878C92;
    p {
      margin: 0.25rem 0;
    }
  } */

  .section-meet-kin .col-3 {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    ${MQ[1]} {
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem;
    }
  }

  .section-pledge h2,
  .section-steps h2,
  .section-meet-kin h2 {
    ${lock(24, 41, 'font-size')}
    ${lock(28, 45, 'line-height')}
    color: ${BRAND_PRIMARY};
    font-family: 'Avenir Next Medium', Arial, sans-serif;
  }
  .section-meet-kin h2 {
    color: ${BRAND_BLACK};
  }

  .section-pledge .col .text,
  .section-steps .col .text {
    ${lock(16, 21, 'font-size')}
    ${lock(20, 28, 'line-height')}
    font-family: "Avenir Next Regular", Arial, sans-serif;
  }

  .section-looking-forward h2 {
    color: ${BRAND_PRIMARY};
  }
  .section-pledge .pledge-intro {
    ${lock(18, 26, 'font-size')}
    ${lock(22, 33, 'line-height')}
  }

  .section-meet-kin {
    .divider {
      display: none;
    }
    .label {
      ${lock(12, 16, 'font-size')}
      ${lock(17, 23, 'line-height')}
      font-family: "Avenir Next Medium", Arial, sans-serif;
      margin: 0;
      padding-top: 3.125rem;
    }
    .col-3 .col {
      margin: 0;
    }
  }

  .sliver {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    ${MQ[1]} {
      flex-direction: row;
      justify-content: space-around;
    }
    .link {
      ${lock(17, 26, 'font-size')}
      ${lock(18, 32, 'line-height')}
    }
  }

  .sliver .text {
    ${lock(20, 28, 'font-size')}
    ${lock(23, 32, 'line-height')}
  }

  .sliver.resume {
    font-family: 'Avenir Next Medium', Arial, sans-serif;
    text-align: left;
    align-items: flex-start;
    p {
      margin-top: 0;
    }

    ${MQ[1]} {
      align-items: center;
      & > div:nth-of-type(1) {
        width: 65%;
      }
      & > div:nth-of-type(2) {
        width: 30%;
      }
      p {
        margin-top: 1rem;
      }
    }
  }

  .section-culture-pillars {
    h2 {
      ${lock(24, 40, 'font-size')}
      ${lock(28, 44, 'line-height')}
      font-family: "Avenir Next", Arial, sans-serif;
      font-style: normal;
      font-weight: 500;
      letter-spacing: -0.02em;
      margin-bottom: 1.75rem;
      text-transform: capitalize;
    }

    .inner-content {
      display: flex;
      align-items: center;
      margin-bottom: 1.6875rem;

      img {
        display: block;
        height: 2.875rem;
        margin-right: 1.25rem;
        width: 2.875rem;
      }

      .text {
        p {
          ${lock(15, 19, 'font-size')}
          ${lock(20, 25, 'line-height')}
          font-family: "Avenir Next", Arial, sans-serif;
          font-style: normal;
          font-weight: 500;
          letter-spacing: -0.01em;
          margin: 0;

          & + p {
            font-weight: normal;
          }
        }
      }
    }
  }

  #sub-nav-line {
    border: 0.530643px solid rgba(16, 24, 32, 0.2);
    color: rgba(16, 24, 32, 0.2);
    margin: 0 1.8125rem;

    ${MQ[1]} {
      margin: 0 1.8125rem;
    }

    // bp 768px
    ${MQ[2]} {
      margin: 0 2.375rem;
    }

    // bp 992px
    ${MQ[3]} {
      margin: 0 3.125rem;
    }

    // bp 1200px
    ${MQ[4]} {
      margin: 0 3.75rem;
    }
    // bp 1920px
    ${MQ[5]} {
      margin: 0 6rem;
    }
  }
`

export default layout
