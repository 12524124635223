import { css } from '@emotion/react'

import { lock } from '../utils/css'
import { BRAND_BLACK, BRAND_PRIMARY } from '../design.styles'

const style = css`
  padding: 0 5% 0 5%;

  font-family: 'Avenir Next Regular', Arial, Helvetica, sans-serif;
  font-weight: 500;

  color: ${BRAND_BLACK};

  .title {
    ${lock(44, 70, 'margin-top')}

    ${lock(24, 40, 'font-size')}
    ${lock(28, 44, 'line-height')}

    letter-spacing: -0.02em;
    color: ${BRAND_PRIMARY};
  }

  .jobs {
    ${lock(35, 70, 'margin-top')}
    ${lock(35, 70, 'margin-bottom')}

    .job {
      display: flex;

      justify-content: space-between;

      ${lock(16, 25, 'padding-top')}
      ${lock(16, 25, 'padding-bottom')}

      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      &:first-of-type {
        padding-top: 0;
      }
      &:last-of-type {
        padding-bottom: 0;

        border-bottom: none;
      }

      .jobTitle {
        ${lock(16, 25, 'font-size')}
        ${lock(20, 30, 'line-height')}

        a {
          color: ${BRAND_BLACK};

          text-decoration: underline;

          &:hover {
            text-decoration-color: ${BRAND_PRIMARY};
          }
        }
      }

      .location {
        ${lock(16, 21, 'font-size')}
        font-weight: normal;
        ${lock(20, 28, 'line-height')}
      }
    }
  }
`

export default style
