import { css } from '@emotion/react'

import { lock } from '../utils/css'

import {
  MQ,
  BRAND_PRIMARY,
  BRAND_BLACK,
  desktop,
  centerVertically,
} from '../design.styles'

const style = css`
  position: relative;

  .keen-slider__slide {
    padding-bottom: 0;

    .slide-content-container {
      padding: 1.25rem;
    }

    justify-content: center;

    background-color: #f8f9f8;
    color: ${BRAND_BLACK};

    .slide-wrapper {
      display: flex;
      flex-direction: column;

      ${MQ[1]} {
        flex-direction: row;
        width: 90%;
        height: fit-content;
        margin: auto;
      }
    }

    .slide-image {
      background-color: #fff;
      width: 100%;

      ${MQ[1]} {
        flex: 1;
        padding: 1rem 0 1rem 1rem;
      }

      // Desktop
      ${MQ[2]} {
        /* min-width: 475px; */
      }

      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
        margin: auto;
      }
    }

    .arrow-container--disabled {
      display: none;
    }

    .slide-content {
      position: relative;
      background-color: #fff;
      border-radius: 0 0 6.25rem 0;
      overflow: hidden;

      ${MQ[1]} {
        flex: 1;
      }

      .slide-content-container {
        font-family: 'Avenir Next Regular', Arial, sans-serif;

        ${MQ[2]} {
          background-color: #fff;
          padding: 3.4375rem;
          max-width: 28.4375rem;
          width: 100%;
          margin: auto;
          min-height: 21.4375rem;
          ${centerVertically}
        }

        .label,
        .title {
          margin-bottom: 1.25rem;

          ${MQ[1]} {
            margin-bottom: 1.4375rem;
          }
        }

        .label {
          font-size: 0.75rem;
          font-weight: 600;

          line-height: 140%;

          letter-spacing: 0.07em;
          text-transform: uppercase;

          ${MQ[1]} {
            font-size: 1rem;
          }
        }

        .title {
          font-size: 1.25rem;
          font-weight: 500;

          line-height: 117%; /* or 23px */

          ${MQ[1]} {
            ${lock(18, 28, 'font-size')}
            ${lock(22, 31, 'line-height')}

            letter-spacing: -0.01em;
          }
        }

        .content {
          margin-bottom: 1.875rem;

          font-size: 1rem;
          line-height: 125%; /* or 20px */

          ${MQ[1]} {
            margin-bottom: 2.8125rem;

            ${lock(16, 22, 'font-size')}
            ${lock(16, 28, 'line-height')}
          }
        }

        .link {
          font-weight: 500;
          font-size: 1rem;
          line-height: 134%; /* or 21px */

          text-decoration-line: underline;

          ${MQ[1]} {
            font-size: 1.25rem;
          }
        }
      }
    }
  }

  // -- Arrows
  .arrows.desktop {
    display: none;

    // Show at desktop breakpoints
    ${MQ[1]} {
      display: block;
    }
  }

  .arrows.mobile {
    display: block;

    // Hide at desktop breakpoints
    ${MQ[1]} {
      display: none;
    }
  }

  .arrow-container {
    display: flex;
    z-index: 3;

    // Center the arrow in its container
    justify-content: center;
    align-items: center;

    background-color: transparent;

    // Center the arrow container in its container (Could also be done with flex I suppose)
    ${centerVertically}

    cursor: pointer;

    width: 1.875rem;
    height: 1.875rem;

    border-radius: 50%;

    ${MQ[1]} {
      width: 4.1875rem;
      height: 4.1875rem;
    }
  }

  .arrow-container--left {
    left: 0;

    ${MQ[1]} {
      left: 0.75rem;
    }
  }

  .arrow-container--right {
    left: auto;
    right: 0;

    ${MQ[1]} {
      right: 0.75rem;
    }
  }

  .arrow-container--disabled {
    fill: rgba(255, 255, 255, 0.5);

    cursor: unset;
  }

  .arrow {
    width: 0.5rem;
    height: 0.9375rem;

    ${desktop()} {
      width: 0.75rem;
      height: 1.375rem;
    }
  }

  // -- Navigation dots
  .dots {
    display: flex;
    justify-content: center;
    padding: 2rem 0 2rem 0;
  }

  .dots.perks {
    background-color: #f8f9f8;
    padding: 2rem 0;
  }

  .dot {
    margin: 0 0.25rem;
    padding: 0.25rem;

    border: 1.5px solid ${BRAND_PRIMARY};

    width: 0.5625rem;
    height: 0.5625rem;

    border-radius: 50%;

    background-color: transparent;

    cursor: pointer;

    ${MQ[1]} {
      margin: 0 0.3125rem;
      padding: 0.3125rem;
      border-width: 0.125rem;
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  .dot:focus {
    outline: none;
  }

  .dot.active {
    background: ${BRAND_PRIMARY};

    cursor: unset;
  }

  .slider-title {
    ${lock(24, 39, 'font-size')}
    ${lock(28, 43, 'line-height')}
    text-align: center;
  }

  .slider-description {
    ${lock(18, 25, 'font-size')}
    ${lock(22, 31, 'line-height')}
    text-align: center;
  }

  .slider-content {
    padding-bottom: 0;
    background-color: #f8f9f8;
  }

  .perks-title {
    color: ${BRAND_PRIMARY};
    margin-top: 1rem;
  }

  .perks-description {
    p {
      margin-bottom: 0 !important;
    }
  }
`

export default style
