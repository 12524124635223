import { css } from "@emotion/react";

const style = css`
  .columns {
    display: flex;

    justify-content: space-evenly;

    width: 100%;
  }

  .ContentfulVideo {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export default style;
