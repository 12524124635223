import React, { useRef, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo'

import SimpleReactValidator from 'simple-react-validator'

import style from '../styles/contact-us.styles'

const RESPONSE_SUCCESS_MESSAGE =
  'Thanks for reaching out, someone will get back to you shortly!'
const RESPONSE_ERROR_MESSAGE = 'An error occured'

export default function Contact() {
  const MESSAGE_TYPES =
    process.env.GATSBY_CONTACT_US_MESSAGE_TYPES.split(',') || []

  const [displayForm, setDisplayForm] = useState(true)

  const [email, setEmail] = useState('')
  const [messageType, setMessageType] = useState(
    MESSAGE_TYPES[0] || 'General Inquiries'
  )
  const [message, setMessage] = useState('')

  const [, forceUpdate] = useState()
  const simpleValidator = useRef(
    new SimpleReactValidator({
      forceUpdate,
      element: (message) => (
        <span className="validation-message">{message}</span>
      ),
      messages: {
        email: '* Please provide valid email',
        required: '* Field required',
        min: '* Does not meet minimum length requirement',
        default: '* Invalid',
      },
    })
  )

  const [responseMessage, setResponseMessage] = useState(
    RESPONSE_SUCCESS_MESSAGE
  )

  // The background image (contact-us.png) is 990 * 887px
  const { page, backgroundDesktop } = useStaticQuery(graphql`
    {
      page: contentfulPage(contentful_id: { eq: "79RooK4xx85c8lr2cNwdDA" }) {
        id
        title
      }
      backgroundDesktop: file(relativePath: { eq: "contact-us.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 990) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const imageData = backgroundDesktop.childImageSharp.fluid

  // The message types can be configured dynamically
  // const URL = process.env.GATSBY_CONTACT_US_URL.replace(
  //   "[ENV]",
  //   process.env.NODE_ENV === "development" ? "dev" : "prod"
  // );

  const handleOnSubmit = async (e) => {
    e.preventDefault()

    // const data = { email, messageType, message }

    // Validation
    if (simpleValidator.current.allValid()) {
      try {
        // TODO: Send the Contact Us request to the back end
        // const response = await fetch(URL, {
        //   method: "POST",
        //   headers: {
        //     Accept: "application/json",
        //     "Content-Type": "application/json"
        //   },
        //   body: JSON.stringify(data)
        // });

        setDisplayForm(false)
      } catch (err) {
        console.error('Error', err)

        setResponseMessage(RESPONSE_ERROR_MESSAGE)
        setDisplayForm(false)
      }
    } else {
      simpleValidator.current.showMessages()

      // Use the autoForceUpdate option to do this automatically
      // this.forceUpdate();

      console.log('Form not valid')
    }
  }

  return (
    <>
      <SEO
        title={page.title}
        description="Contact form for Kinship.co"
        bodyAttributes={{ class: 'contact' }}
      />
      <div css={style}>
        <div className="image-desktop">
          {/* Note: For expediency and simplicity, a decision was made to make the label static for now */}
          <div className="title">
            <span className="label">Let's talk</span>
            <h1>Contact</h1>
          </div>
          <Img fluid={imageData} alt="Brown dog" />
        </div>
        <div className="form">
          {displayForm ? (
            <>
              <div className="title">
                <span className="label">Let's talk</span>
                <h1>{page.title}</h1>
              </div>
              <form name="contact" method="POST" data-netlify="true">
                <label>
                  Your Email: <input type="email" name="email" />
                </label>
                <label>
                  Message type
                  <br />
                  <select
                    value={messageType}
                    onBlur={(e) => setMessageType(e.target.value)}
                  >
                    {MESSAGE_TYPES.map((messageType) => (
                      <option value={messageType}>{messageType}</option>
                    ))}
                  </select>
                </label>
                <label>
                  <span>Message </span>

                  <textarea
                    name="message"
                    placeholder="Let us know how we can help..."
                    value={message}
                    rows="2"
                    onChange={(e) => setMessage(e.target.value)}
                    onBlur={simpleValidator.current.showMessageFor('message')}
                  />
                </label>
                <div>
                  <button type="submit">Send Message</button>
                </div>
              </form>

              {/* <form name="contact" method="POST" data-netlify="true">
                <p>
                  <label>
                    Your Email: <input type="email" name="email" />
                  </label>
                </p>
                <p>
                  <label>
                    Message type:{' '}
                    <select
                      value={messageType}
                      onBlur={(e) => setMessageType(e.target.value)}
                    >
                      {MESSAGE_TYPES.map((messageType) => (
                        <option value={messageType}>{messageType}</option>
                      ))}
                    </select>
                  </label>
                </p>
                <p>
                  <label>
                    Message: <textarea name="message"></textarea>
                  </label>
                </p>
                <p>
                  <button type="submit">Send</button>
                </p>
              </form> */}
            </>
          ) : (
            <div className="confirmation">
              <div className="title">
                <span className="label">Let's talk</span>
                <h1>{page.title}</h1>
              </div>
              <div className="message">{responseMessage}</div>
              <div className="button">
                <a role="button" href="https://kinship.co">
                  Return Home
                </a>
              </div>
            </div>
          )}
        </div>
        <Img className="image-mobile" fluid={imageData} alt="Brown dog" />
      </div>
    </>
  )
}
