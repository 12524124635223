/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
        siteDesc: description
        author
        image
      }
    }
  }
`

const SEO = ({ description, lang, title, hero, bodyAttributes, style }) => {
  const { site } = useStaticQuery(query)
  const { siteDesc, image, author } = site.siteMetadata

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      bodyAttributes={bodyAttributes}
    >
      <meta name="description" content={description || siteDesc} />
      <meta name="image" content={image} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={`https:${hero}`} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={author} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" data-document-language="true" type="text/javascript" charset="UTF-8" data-domain-script="1a3b8fa3-a990-4e35-bcd7-91e20d0af5d4" ></script>
      <script type="text/javascript">function OptanonWrapper() { }</script>
      <script async src="https://unpkg.com/thesemetrics@latest" />
      {style && <style type="text/css">{style}</style>}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
  style: '',
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  style: PropTypes.string,
}

export default SEO
