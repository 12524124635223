import React from "react";

import PropTypes from "prop-types";

import Container from "./Container";
import Media from "./Media";

import style from "./Section1Col.styles";

const Section1Col = ({ title, content }) => (
  <div className="inner-section" css={style}>
    {title && <h2>{title}</h2>}
    {content &&
      content.map(item => {
        return (
          <div key={item.id}>
            {/* {console.log(item.text)} */}
            {item.__typename === "ContentfulContainer" ? (
              <Container
                title={item.title}
                topic={item.topic}
                content={item.content}
                cssClass={item.cssClass}
                text={item.text}
              />
            ) : null}

            {item.__typename === "ContentfulMedia" ? (
              <Media media={item.media} alt={item.media.description} />
            ) : null}
          </div>
        );
      })}
  </div>
);

// TODO: Change/simplify the component's interface if necessary (for example, if everything comes in through a
//       `section` or `data` prop)
Section1Col.defaultProps = {
  backgroundColor: "aliceblue"
};

Section1Col.propTypes = {
  backgroundColor: PropTypes.string
};

export default Section1Col;
