import React, { useEffect, useRef, useState } from 'react'
import Link from 'gatsby-link'

import { SubNavCSS } from './SubNav.styles'

/**
 * TODO - uncomment Events link when /events is complete
 */
const SubNav = ({ addBgColor }) => {
  const [active, toggleActive] = useState(false)
  const menuRef = useRef(null)
  useEffect(() => {
    menuRef.current.style.maxHeight = active
      ? `${menuRef.current.scrollHeight}px`
      : `0px`
  }, [active])

  return (
    <>
      <hr id="sub-nav-line" />
      <SubNavCSS className="padded" addBgColor={addBgColor}>
        <button id="header" onClick={() => toggleActive(!active)}>
          <p>News & Events</p>
          <div className={active ? 'rotate' : 'no-rotate'}>
            <img
              id="arrow"
              src={'/img/arrow-left-content.svg'}
              alt="Left arrow"
            />
          </div>
        </button>
        <div id="collapse" ref={menuRef}>
          <Link to="/news">View All</Link>
          <Link to="/news/culture-trends">Culture & Trends</Link>
          <Link to="/news/science-technology">Science & Technology</Link>
          <Link to="/news/startups-business">Startups & Business</Link>
          {/* <Link to="/events">Events</Link> */}
        </div>
        <div id="inline-nav">
          <Link to="/news" id="bold">
            News & Events
          </Link>
          {'|'}
          <Link to="/news/culture-trends">Culture & Trends</Link>
          <Link to="/news/science-technology">Science & Technology</Link>
          <Link to="/news/startups-business">Startups & Business</Link>
          {/* <Link to="/events">Events</Link> */}
        </div>
      </SubNavCSS>
    </>
  )
}

export default SubNav
