import React from "react";

import PropTypes from "prop-types";
import Container from "./Container";
import Card from "./Card";

import Img from "gatsby-image";
import Link from "gatsby-link";

import style from "./Section3Col.styles";

const Section3Col = ({ title, label, content }) => (
  <div css={style}>
    {label && <div className="label">{label}</div>}
    {title && <h2>{title}</h2>}
    <div className="divider" />

    <div className="col-3">
      {content &&
        content.map(item => {
          const categorySlug = ("" + item.category)
            .toLowerCase()
            .trim()
            .replace(/[^a-z0-9]+/g, "-");
          const path = `/news/${categorySlug}/${item.slug}`;

          return (
            <div className="col" key={item.id}>
              {item.__typename === "ContentfulContainer" ? (
                <Container
                  title={item.title}
                  content={item.content}
                  cssClass={item.cssClass}
                />
              ) : null}

              {item.__typename === "ContentfulMedia" ? (
                <Img
                  fluid={item.media.fluid}
                  alt={item.media.description}
                  class={item.class}
                />
              ) : null}

              {item.__typename === "ContentfulArticle" ? (
                <article>
                  <Link to={path}>
                    <Img fluid={item.hero.fluid} alt={item.hero.description} />
                  </Link>{" "}
                  <div className="category">{item.category}</div>
                  <h3>
                    <Link to={path}>{item.title}</Link>
                  </h3>
                  <div className="read-more">
                    <Link to={path}>Read more</Link>
                  </div>
                </article>
              ) : null}

              {item.__typename === "ContentfulSlide" ? (
                <Card
                  title={item.title}
                  label={item.label}
                  text={item.text}
                  media={item.media}
                  linkText={item.linkText}
                  linkUrl={item.linkUrl}
                />
              ) : null}
            </div>
          );
        })}
    </div>
  </div>
);

// TODO: Change/simplify the component's interface if necessary (for example, if everything comes in through a
//       `section` or `data` prop)
Section3Col.defaultProps = {
  content: "Content"
};

Section3Col.propTypes = {
  content: PropTypes.object.isRequired
};

export default Section3Col;
