import { css } from '@emotion/react'

import { lock } from '../utils/css'
import { BRAND_PRIMARY, BRAND_BLACK, MQ } from '../design.styles'

const style = css`
  display: flex;

  height: calc(100vh - 6.25rem);

  .content {
    flex-basis: 100%;

    ${MQ[1]} {
      flex-basis: 50%;
    }

    .title {
      font-family: 'Avenir Next Regular', Arial, Helvetica, sans-serif;
      font-weight: 600;

      font-size: 0.75rem;
      line-height: 1.0625rem;

      ${lock(12, 16, 'font-size')}
      ${lock(17, 22, 'line-height')}

      letter-spacing: 0.07em;
      text-transform: uppercase;

      color: ${BRAND_BLACK};
    }

    .message {
      margin-top: 0.875rem;

      ${MQ[1]} {
        margin-top: 1rem;
      }

      font-family: 'Avenir Next Regular', Arial, Helvetica, sans-serif;

      ${lock(18, 26, 'font-size')}
      ${lock(22, 32, 'line-height')}

      color: ${BRAND_BLACK};
    }

    a[role='button'] {
      display: block;

      margin: 1.625rem 0 7.5rem;

      width: 11.25rem;
      height: 2.8125rem;

      background: ${BRAND_PRIMARY};
      border-radius: 1.75rem;

      line-height: 2.8125rem;

      font-family: 'Avenir Next Regular', Arial, Helvetica, sans-serif;
      font-weight: 500;

      font-size: 1.0625rem;

      letter-spacing: 0.03em;

      color: #fff;

      text-align: center;
      text-decoration: none;

      ${MQ[1]} {
        margin: 1.625rem 0 7.5rem;

        width: 15.625rem;
        height: 3.4375rem;

        border-radius: 1.9375rem;

        line-height: 3.4375rem;

        font-size: 1.3125rem;

        letter-spacing: 0.02em;
      }
    }
  }

  .no-content {
    display: none;

    ${MQ[1]} {
      display: block;

      flex-basis: 50%;
    }
  }
`

export default style
