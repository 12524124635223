import React, { useEffect, useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/react'
import { BRAND_PRIMARY } from '../design.styles'

const Navigation = styled.nav`
  display: flex;
  font-family: 'Avenir Next Regular';
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  z-index: 10;

  svg {
    width: 12rem;
  }
  &.mobile-nav-active {
    background-color: ${BRAND_PRIMARY};
    svg {
      fill: #fff;
    }
    .icon,
    .icon::before,
    .icon::after {
      background-color: #fff;
    }
  }

  @media (max-width: 47.9375rem) {
    background-color: #fff;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    left: 0;
  }
`

const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  @media (max-width: 47.9375rem) {
    display: flex;
    outline: none;
  }
`

const Navbox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 47.9375rem) {
    height: calc(100vh - 6.25rem);

    color: #fff;
    font-family: 'Avenir Next Regular', Arial, sans-serif;
    font-size: 1.8125rem;
    flex-direction: column;
    position: fixed;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 10vh;
    background-color: ${BRAND_PRIMARY};
    transition: all 0.3s ease-in;
    top: 5.8125rem;
    left: ${(props) => (props.open ? '-100%' : '0')};
    a {
      color: #fff;
    }
  }
`

const Hamburger = styled.div`
  background-color: ${BRAND_PRIMARY};
  width: 1.875rem;
  height: 0.1875rem;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${(props) => (props.open ? 'rotate(-45deg)' : 'inherit')};
  ::before,
  ::after {
    width: 1.875rem;
    height: 0.1875rem;
    background-color: ${BRAND_PRIMARY};
    content: '';
    position: absolute;
    transition: all 0.3s linear;
    transform: ${(props) => (props.open ? 'rotate(-45deg)' : 'inherit')};
    @media (max-width: 47.9375rem) {
      &.nav-mobile-active {
        background-color: #fff;
      }
    }
  }

  ::before {
    transform: ${(props) =>
      props.open ? 'rotate(-90deg) translate(-10px, 0px)' : 'rotate(0deg)'};
    top: -0.625rem;
  }

  ::after {
    opacity: ${(props) => (props.open ? '0' : '1')};
    transform: ${(props) => (props.open ? 'rotate(90deg) ' : 'rotate(0deg)')};
    top: 0.625rem;
  }
`

const NavItem = styled(Link)`
  text-decoration: none;
  color: #111;
  display: inline-block;
  white-space: nowrap;
  margin-right: 2rem;
  transition: all 200ms ease-in;
  position: relative;
  :last-of-type {
    margin-right: 0;
  }

  :hover {
    color: ${BRAND_PRIMARY};
    text-decoration: underline;
  }
  &[aria-current='page'] {
    border-bottom: 2px solid ${BRAND_PRIMARY};
  }
  @media (max-width: 47.9375rem) {
    padding: 1.25rem;
    font-size: 1.5rem;
    z-index: 6;
    :hover {
      color: white;
    }
  }
`

const Header = ({ currentPage }) => {
  const [navbarOpen, setNavbarOpen] = useState(false)
  const { redLogo, techBlogLogo } = useStaticQuery(graphql`
    query {
      redLogo: contentfulAsset(title: { eq: "Kinship Logo Red" }) {
        title
        svg {
          content
        }
      }
      techBlogLogo: contentfulAsset(title: { eq: "KinshipTechBlog RGB" }) {
        title
        svg {
          content
        }
      }
    }
  `)

  useEffect(() => {
    const bodyTag = document.querySelector('body')
    bodyTag.classList.toggle('modal-open', navbarOpen)
  }, [navbarOpen])

  return (
    <Navigation className={`padded ${navbarOpen ? 'mobile-nav-active' : ''}`}>
      <Global
        styles={css`
          body.modal-open {
            overflow: hidden;
          }
        `}
      />
      <Link to="/">
        <div
          className="header-logo-div"
          dangerouslySetInnerHTML={{
            __html: currentPage.includes('/techblog')
              ? techBlogLogo.svg.content
              : redLogo.svg.content,
          }}
        />
        <span className="sr-only">Kinship</span>
      </Link>

      <Toggle
        navbarOpen={navbarOpen}
        onClick={() => setNavbarOpen(!navbarOpen)}
        tabIndex="0"
        role="button"
        aria-pressed="false"
        aria-label="Toggle navigation"
      >
        {navbarOpen ? (
          <Hamburger className="icon" open />
        ) : (
          <Hamburger className="icon" />
        )}
      </Toggle>
      {navbarOpen ? (
        <Navbox>
          <NavItem to="/about" onClick={() => setNavbarOpen(!navbarOpen)}>
            About Us
          </NavItem>
          {/* <NavItem to="/news" onClick={() => setNavbarOpen(!navbarOpen)}>
            News & Events
          </NavItem> */}
          <NavItem to="/careers" onClick={() => setNavbarOpen(!navbarOpen)}>
            Careers & Culture
          </NavItem>
          <a href="mailto:communications@kinship.co" className="topnav-link">
            Contact Us
          </a>
        </Navbox>
      ) : (
        <Navbox open>
          <NavItem to="/about">About Us</NavItem>
          {/* <NavItem to="/news">News & Events</NavItem> */}
          <NavItem to="/careers">Careers & Culture</NavItem>
          <a href="mailto:communications@kinship.co" className="topnav-link">
            Contact Us
          </a>
        </Navbox>
      )}
    </Navigation>
  )
}

export default Header
