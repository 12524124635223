import { css } from '@emotion/react'

import { lock } from '../utils/css'
import { BRAND_PRIMARY, BRAND_BLACK, BRAND_BLUE, MQ } from '../design.styles'

const style = css`
  display: flex;
  flex-direction: column;
  ${MQ[1]} {
    flex-direction: row;
  }

  .image-mobile {
    flex-basis: 100%;
    display: block;
    min-height: 25rem;

    // If device width is >= 576px...
    ${MQ[1]} {
      display: none;
    }
  }

  .image-desktop {
    position: relative;
    flex-basis: 50%;
    min-height: 38rem;
    display: none;

    ${MQ[1]} {
      display: block;
    }

    /* On desktop, the title is displayed with the image... */
    .title {
      display: none;

      ${MQ[1]} {
        display: block;
      }
    }

    .gatsby-image-wrapper {
      position: absolute !important;

      bottom: 0;

      max-width: 30.625rem;
      width: 100% !important;
    }
  }

  .form {
    flex-basis: 100%;
    ${lock(25, 60, 'padding-right')}
    ${lock(25, 60, 'padding-left')}
    ${MQ[1]} {
      flex-basis: 50%;
    }

    /* On mobile, the title is displayed above the form... */
    .title {
      display: block;

      ${MQ[1]} {
        display: none;
      }
    }

    label {
      display: block;

      /* TODO: What's the correct value for mobile? */
      margin-top: 2.75rem;

      &:first-of-type {
        margin-top: 1.875rem;

        ${MQ[1]} {
          margin-top: 0;
        }
      }

      font-family: 'Avenir Next Regular', Arial, Helvetica, sans-serif;
      font-weight: 600;
      ${lock(12, 16, 'font-size')}
      ${lock(17, 22, 'line-height')}

      letter-spacing: 0.07em;
      text-transform: uppercase;

      input,
      select,
      textarea {
        width: 100%;

        margin-top: 1.5rem;

        border: none;
        border-bottom: 0.125rem solid ${BRAND_BLACK};

        font-family: 'Avenir Next Regular', Arial, Helvetica, sans-serif;
        ${lock(16, 21, 'font-size')}
        ${lock(20, 28, 'line-height')}

        color: ${BRAND_BLACK};

        &:focus {
          outline: 0;
        }
      }

      input,
      textarea {
        &::placeholder {
          font-family: 'Avenir Next Regular', Arial, Helvetica, sans-serif;
          ${lock(16, 21, 'font-size')}
          ${lock(20, 28, 'line-height')}

          color: rgba(16, 24, 32, 0.5);
        }
      }

      input,
      select {
        padding-bottom: 1.4375rem;
      }

      .validation-message {
        color: ${BRAND_BLUE};

        text-transform: none;
      }
    }

    button {
      /* TODO: What's the correct value for mobile? */
      margin-top: 2.75rem;

      width: 100%;
      min-height: 3.4375rem;

      border: none;

      border-radius: 1.9375rem;

      background-color: ${BRAND_PRIMARY};
      color: white;

      font-family: 'Avenir Next Regular', Arial, Helvetica, sans-serif;
      font-weight: 500;
      ${lock(17, 21, 'font-size')}
      ${lock(21, 26, 'line-height')}

      letter-spacing: 0.03em;

      ${MQ[1]} {
        max-width: 16.25rem;

        letter-spacing: 0.02em;
      }
    }
  }

  .title {
    ${lock(29, 60, 'margin-left')}

    & > .label {
      font-family: 'Avenir Next Regular', Arial, Helvetica, sans-serif;
      font-weight: 600;

      ${lock(12, 16, 'font-size')}
      ${lock(17, 22, 'line-height')}

      letter-spacing: 0.07em;
      text-transform: uppercase;

      color: ${BRAND_BLACK};
    }
  }

  .confirmation {
    ${lock(29, 60, 'margin-right')}

    .title {
      margin-left: 0;
    }

    .message {
      ${lock(14, 60, 'margin-top')}

      font-family: "Avenir Next Regular", Arial, Helvetica, sans-serif;
      font-weight: 500;

      ${lock(24, 40, 'font-size')}
      ${lock(28, 44, 'line-height')}

      letter-spacing: -0.02em;

      color: #4d5258; /* Not BRAND_BLACK? */
    }

    .button {
      position: relative;

      ${lock(23, 35, 'margin-top')}

      border: 0.1875rem solid ${BRAND_PRIMARY};
      border-radius: 2.1875rem;

      max-width: 15.9375rem;
      height: 3.4375rem;

      a[role='button'] {
        position: absolute;

        top: 50%;
        transform: translateY(-50%);

        width: 100%;

        text-align: center;

        font-family: 'Avenir Next Regular', Arial, Helvetica, sans-serif;
        font-weight: 600;

        ${lock(17, 21, 'font-size')}
        ${lock(21, 26, 'line-height')}

        letter-spacing: 0.03em;

        color: ${BRAND_PRIMARY};

        text-decoration: none;
      }
    }

    margin-bottom: 1.875rem;

    ${MQ[1]} {
      margin-bottom: 0;
    }
  }
`

export default style
